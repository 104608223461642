"use strict"

import React from "react"
import styled from "styled-components"

import theme from "../theme"
import { ResetCSS } from "./parts/styled"

// ###################################
const FooterS = styled.footer`
  ${ResetCSS};

  padding: ${theme.spacing(2)}px;
  text-align: center;
`

// ###################################
const Footer = () => <FooterS>&copy; 山一窯業株式会社</FooterS>

export default Footer
