"use strict"

import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import theme from "../../theme"

const scrollTo = () => {
  window.scrollTo({ top: 0, behavior: "smooth" })
}

const Wrapper = styled.div`
  z-index: 1;
  position: fixed;
  bottom: 5px;
  right: ${theme.prime.sp011}px;

  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
`

const ScrollToTop = () => {
  const [onTop, setOnTop] = useState(0)

  function onScroll() {
    const offset = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    )

    if (offset > 20) {
      setOnTop(1)
    }

    if (offset <= 20) {
      setOnTop(0)
    }
  }

  useEffect(() => {
    window.addEventListener(`scroll`, onScroll)

    return () => window.removeEventListener(`scroll`, onScroll)
  })

  return (
    <Wrapper style={{ opacity: `${onTop}` }} onClick={scrollTo}>
      <VerticalAlignTopIcon fontSize={`large`} />
    </Wrapper>
  )
}

export default ScrollToTop
