"use strict"

import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import theme from "../../theme"

// ###################################
export const ResetCSS = css`
  margin: 0;
  padding: 0;
`
// ###################################
export const LinkWithNoDecoBlack = styled(Link)`
  ${ResetCSS};

  color: ${theme.palette.common.black};
  display: inline-block;
  text-decoration: none;
`
// ###################################
export const TypographyS = styled(Typography)`
  border-bottom: 1px solid silver;
  margin-bottom: ${theme.spacing(3)}px;
  padding-bottom: ${theme.spacing(3)}px;
`
// ###################################
export const Spacer = styled.hr`
  ${ResetCSS};

  border: none;
  height: ${(props) => theme.spacing(props.v)}px;
`
Spacer.defaultProps = {
  v: 5,
}
