"use strict"

import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"

import theme from "../../theme"
import { ResetCSS } from "./styled"

// ###################################
const VideoS = styled.div`
  ${ResetCSS};

  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
  height: 100%;

  & {
    @media screen and (min-width: 1280px) {
      margin-bottom: -${theme.prime.sp101}px;
    }
    @media screen and (max-width: 1279px) {
      margin-bottom: ${theme.prime.sp101}px;
    }
  }

  & iframe {
    ${ResetCSS};

    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`
// ###################################
const VideoTitle = styled.div`
  ${ResetCSS};

  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & h1 {
    ${ResetCSS};

    color: ${theme.palette.common.white};
    font-family: "Noto Serif JP", "Roboto", sans-serif;
    font-size: ${theme.prime.sp037}px;
    font-weight: bold;

    @media screen and (max-width: ${theme.width.breakpoint}px) {
      font-size: ${theme.prime.sp019}px;
    }
  }
`

// ###################################
const Video = () => {
  const data = useStaticQuery(graphql`
    query VideoQuery {
      site {
        siteMetadata {
          video {
            id
            title
          }
        }
      }
    }
  `)

  let params = [
    `autoplay=1`,
    `controls=0`,
    `disablekb=1`,
    `iv_load_policy=3`,
    `loop=1`,
    `modestbranding=1`,
    `rel=1`,
    `mute=1`,
    `playlist=` + data.site.siteMetadata.video.id,
  ]

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [trim, setTrim] = useState(0)

  React.useEffect(() => {
    const trimSize = () => {
      let vh = (window.innerWidth * 9) / 16
      let wh = window.innerHeight - 64

      if (vh > wh) {
        setTrim((vh - wh) / 2)
        setHeight(vh - trim)
      } else {
        setTrim(0)
        setHeight(vh)
      }

      setWidth(window.innerWidth)
    }

    trimSize()

    window.addEventListener(`resize`, () => {
      trimSize()
    })
    return () => window.removeEventListener(`resize`, () => {})
  }, [width, height, trim])

  return (
    <VideoS>
      <VideoTitle
        className={`video-title`}
        style={{ height: `${height - trim}px` }}
      >
        <div>&nbsp;</div>

        <h1>{data.site.siteMetadata.video.title}</h1>

        <div>&nbsp;</div>
      </VideoTitle>

      <iframe
        style={{
          clip: `rect(${trim}px, ${width}px, ${height}px, 0px`,
          marginTop: `-${trim}px`,
        }}
        src={
          `https://www.youtube.com/embed/` +
          data.site.siteMetadata.video.id +
          `?` +
          params.join(`&`)
        }
        allow={`accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture`}
        allowFullScreen
        frameBorder={`0`}
        title={data.site.siteMetadata.video.title}
      />
    </VideoS>
  )
}

export default Video
