"use strict"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import theme from "../../theme"
import Image from "../parts/image"
import { LinkWithNoDecoBlack, ResetCSS } from "../parts/styled"
import TemporaryDrawer from "./drawer"

// ###################################
const AppBarS = styled(AppBar)`
  ${ResetCSS};

  background-color: ${theme.palette.common.white}!important;
`
// ###################################
const Logo = styled.div`
  ${ResetCSS};

  flex-grow: 1;
`
// ###################################
const Links = styled(LinkWithNoDecoBlack)`
  ${ResetCSS};

  margin: ${theme.spacing(1)}px 0;
  width: ${theme.width.logo}px;
`

// ###################################
const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/*https://material-ui.com/api/app-bar/*/}
      <AppBarS position={`fixed`}>
        {/*https://material-ui.com/api/toolbar/*/}
        <Toolbar>
          <Logo>
            <Links to={`/`}>
              <Image filename={`logo.jpg`} alt={data.site.siteMetadata.title} />
            </Links>
          </Logo>

          <TemporaryDrawer />
        </Toolbar>
      </AppBarS>

      <Toolbar />
      {/*<TemporaryDrawer />*/}
    </>
  )
}

export default Header
