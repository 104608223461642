"use strict"

import "../../scss/burger-menu.css"

import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { slide as Menu } from "react-burger-menu"
import styled from "styled-components"

import theme from "../../theme"
import { LinkWithNoDecoBlack } from "../parts/styled"
import { RandNum } from "../parts/utils"

/**
 * @property {object} allDrawerJson
 * @property {object} menu0
 * @property {object} menu1
 */

const WrapperBurger = styled.div`
  display: none;

  & {
    @media screen and (max-width: 860px) {
      display: block;
    }
  }
`
// ###################################
const UlS = styled.ul`
  color: ${theme.palette.common.black};

  & li {
    border-right: 1px solid gray;
    display: inline-block;
    margin: 0 23px 0 0;
    padding: 0 23px 0 0;
  }
  & li:last-child {
    border: none;
    padding: 0;
  }
  & {
    @media screen and (max-width: 860px) {
      display: none;
    }
  }
`

// ###################################
const TemporaryDrawer = () => {
    const menu = useStaticQuery(graphql`
    query MenuQuery {
      allDrawerJson {
        edges {
          node {
            menu0 {
              a
              to
            }
            menu1 {
              a
              to
            }
          }
        }
      }
    }
  `)

    return (
        <>
            <WrapperBurger>
                <Menu right>
                    {menu.allDrawerJson.edges[0].node.menu0.map((c) => (
                        <Link to={c.to} className={`menu-item`} key={RandNum()}>
                            {c.a}
                        </Link>
                    ))}
                </Menu>
            </WrapperBurger>

            <UlS>
                {menu.allDrawerJson.edges[0].node.menu0.map((c) => (
                    <li key={RandNum()}>
                        <LinkWithNoDecoBlack to={c.to}>{c.a}</LinkWithNoDecoBlack>
                    </li>
                ))}
            </UlS>
        </>
    )
}

export default TemporaryDrawer
