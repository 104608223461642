"use strict"

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "../scss/base.css"

import { Container } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiThemeProvider } from "@material-ui/core/styles"
import PageTransition from "gatsby-plugin-page-transitions"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import theme from "../theme"
import Footer from "./footer"
import Header from "./header/header"
import ScrollToTop from "./parts/scrollToTop"
import { ResetCSS } from "./parts/styled"
import Video from "./parts/video"

/*
 * The Import order
 *  1. react
 *  2. prop-types
 *  3. gatsby
 *  4. plugins
 *  5. Material UI
 *  6. basic css
 *  7. components
 *  8. pages
 *  9. css or scss
 */

/**
 * @property {object} site
 */

// ###################################
const Wrapper = styled.div`
  ${ResetCSS};

  background-color: ${theme.palette.common.white};
  overflow: hidden;
`

// ###################################
const Layout = ({ children }) => (
    <PageTransition
        defaultStyle={{
            transition: `opacity 2000ms ease-in-out`,
            opacity: 0,
        }}
        transitionStyles={{
            entering: { opacity: 0.5 },
            entered: { opacity: 1 },
            exiting: { opacity: 0.3 },
            exited: { opacity: 0 },
        }}
    >
        <MuiThemeProvider theme={theme}>
            <CssBaseline />

            <Header />

            <Wrapper>
                {children[0].props.title === `` ? <Video /> : null}

                {/*https://material-ui.com/api/container/*/}
                <Container fixed maxWidth={`lg`} component={`main`}>
                    {children}
                </Container>
            </Wrapper>

            <ScrollToTop />

            <Footer />
        </MuiThemeProvider>
    </PageTransition>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
